import { get, post } from 'common/request'

export default {
  // 基础tree
  // list: args => get('/observation/task/list', args),

  getTree: args => get('/observation/group/getTree', args),

  addTree: args => post('/observation/group/add', args),

  delTree: args => get('/observation/group/del', args),

  // 基础表格
  basequery: args => get('/observationCategoryShip/query', args),
  baseadd: args => post('/observationCategoryShip/add', args),
  baseList: args => get('observationCategoryShip/pageQueryParent', args),
  basedel: args => get('observationCategoryShip/del', args),

  // 组合 tree
  getCompTree: args => get('/observationGroupCategory/getPcTree', args),
  addCompTree: args => post('/observationGroupCategory/add', args),
  delCompTree: args => get('/observationGroupCategory/del', args),
  getShpList: args => get('/observationGroupCategory/getShp', args),

  // 文件上传
  uploadFile: args => post('/attachFile/upload', args)
}
